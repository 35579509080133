import React, { useState } from "react"
import { Card, CardBody, Button } from "reactstrap"
import Utils from "../../Common/Utils"
import CommentBeforeActionModal from "../../Modals/CommentBeforeActionModal"

const ActionsArea = ({ transaction, isLoading, onActionPerformed }) => {
  const [toReject, setToReject] = useState(false)
  const [toProcess, setToProcess] = useState(false)

  const forwardAction = (action, data) => {
    if (onActionPerformed) {
      onActionPerformed(action, data)
    }
  }

  const onRejectClicked = () => {
    setToReject(true)
  }
  const onReject = comment => {
    comment = `Rejected halted ${transaction.type.toLowerCase()} order. ${comment}`
    setToReject(false)
    forwardAction("tx_reject", { comment })
  }

  const onProcessClicked = () => {
    setToProcess(true)
  }
  const onProcess = comment => {
    comment = `Processed halted ${transaction.type.toLowerCase()} order. ${comment}`
    setToProcess(false)
    forwardAction("tx_process", { comment })
  }

  const onBanlistAddressClicked = () => {
    Utils.showSweetAlertAreYouSure(
      "Banlist this transaction address?",
      "",
      null,
    ).then(confirmed => {
      if (confirmed) {
        forwardAction("banlist_address")
      }
    })
  }

  const onBumpTransactionClicked = () => {
    Utils.showSweetAlertAreYouSure(
      "Bump transaction?",
      "Bumping needs to happen in-order to work",
      null,
    ).then(confirmed => {
      if (confirmed) {
        forwardAction("bump_transaction")
      }
    })
  }

  const CAN_BUMP_LIST = ["ETH", "LINK", "MATIC", "USDC", "UNI"]

  const getButtonList = () => {
    let buttons = []
    if (!transaction) return buttons
    if (transaction.status === "HALTED" || transaction.status === "PENDING_TR") {
      // Always add "Process" and "Reject" button on HALTED and PENDING_TR transactions
      buttons.push({
        title: "Process",
        background: "#2E8B57",
        icon: "thumbs-up",
        onClick: onProcessClicked,
      })
      buttons.push({
        title: "Reject",
        background: "#B22222",
        icon: "thumbs-down",
        onClick: onRejectClicked,
      })
    }
    buttons.push({
      title: "Report (XML)",
      background: "#243948",
      icon: "flag",
      onClick: () => forwardAction("report_xml"),
    })

    if (transaction.type === "WITHDRAWAL") {
      buttons.push({
        title: "Banlist address",
        background: "#000000",
        onClick: onBanlistAddressClicked,
      })
    }

    if (
      transaction.type === "WITHDRAWAL" &&
      transaction.status === "PROCESSING" &&
      CAN_BUMP_LIST.includes(transaction.currency)
    ) {
      buttons.push({
        title: "Bump (stuck) transaction",
        background: "#f3a202",
        onClick: onBumpTransactionClicked,
      })
    }

    return buttons
  }

  const buttons = getButtonList()
  if (!buttons.length) return ""

  return (
    <div>
      <span className="header-area">Actions</span>
      <Card className="card-area">
        <CardBody
          className={isLoading ? "whirl standard" : ""}
          style={
            isLoading && buttons.length === 0 ? { minHeight: "100px" } : {}
          }
        >
          <div style={{ textAlign: "center" }}>
            {buttons.map((item, i) => {
              let className = "action_button"
              if (i + 1 < buttons.length) className += " mb-2"
              return (
                <Button
                  key={i}
                  className={className}
                  style={{ background: item.background }}
                  onClick={item.onClick}
                >
                  <i className={"fas fa-" + item.icon}></i>
                  {item.title}
                </Button>
              )
            })}
          </div>
        </CardBody>
      </Card>
      {/* Comment modal for proceed */}
      <CommentBeforeActionModal
        isOpen={toProcess}
        actionName={"Process order"}
        onClose={() => setToProcess(false)}
        onDone={onProcess}
      />
      {/* Comment modal for reject */}
      <CommentBeforeActionModal
        isOpen={toReject}
        actionName={"Reject order"}
        onClose={() => setToReject(false)}
        onDone={onReject}
      />
    </div>
  )
}

export default ActionsArea

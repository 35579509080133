import Cart from "../Media/Svg/cart.svg"
import Trash from "../Media/Svg/trash_white.svg"
import Cash from "../Media/Svg/cash.svg"
import Freeze from "../Media/Svg/freeze.svg"
import ExchangeWhite from "../Media/Svg/exchange_white.svg"
import Exchange from "../Media/Svg/exchange.svg"
import ArrowUp from "../Media/Svg/arrow_up.svg"
import Transfer from "../Media/Svg/transfer.svg"
import React from "react"
import Comment from "../Media/Svg/comment.svg"
import { APP_COLORS } from "../Common/constants"
import { DataGridLocalDateTimeFormatter } from "../Common/Utils"

const cryptoExplorerUrls = {
  address: {
    BTC: "https://blockchair.com/bitcoin/address/",
    ETH: "https://etherscan.io/address/",
    LINK: "https://etherscan.io/address/",
    MATIC: "https://etherscan.io/address/",
    USDC: "https://etherscan.io/address/",
    UNI: "https://etherscan.io/address/",
    SHIB: "https://etherscan.io/token/0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce?a=",
    LDO: "https://etherscan.io/token/0x5a98fcbea516cf06857215779fd812ca3bef1b32?a=",
    AAVE: "https://etherscan.io/token/0x7fc66500c84a76ad7e9c93437bfc5ac33e2ddae9?a=",
    GRT: "https://etherscan.io/token/0xc944e90c64b2c07662a292be6244bdf05cda44a7?a=",
    MKR: "https://etherscan.io/token/0x9f8f72aa9304c8b593d555f12ef6589cc3a579a2?a=",
    SAND: "https://etherscan.io/token/0x3845badAde8e6dFF049820680d1F14bD3903a5d0?a=",
    COMP: "https://etherscan.io/token/0xc00e94cb662c3520282e6f5717214004a7f26888?a=",
    BCH: "https://blockchair.com/bitcoin-cash/address/",
    XRP: "https://bithomp.com/explorer/",
    ALGO: "https://explorer.perawallet.app/address/",
    SOL: "https://explorer.solana.com/address/",
    AVAX: "https://subnets.avax.network/p-chain/address/",
    DOGE: "https://blockchair.com/dogecoin/address/",
    LTC: "https://blockchair.com/litecoin/address/",
    JUP: "https://explorer.solana.com/address/",
    WIF: "https://explorer.solana.com/address/",
    SNX: "https://etherscan.io/address/",
    WOO: "https://etherscan.io/address/",
    FET: "https://etherscan.io/address/",
    YFI: "https://etherscan.io/address/",
  },
  tx: {
    BTC: "https://blockchair.com/bitcoin/transaction/",
    ETH: "https://etherscan.io/tx/",
    LINK: "https://etherscan.io/tx/",
    MATIC: "https://etherscan.io/tx/",
    USDC: "https://etherscan.io/tx/",
    UNI: "https://etherscan.io/tx/",
    SHIB: "https://etherscan.io/tx/",
    LDO: "https://etherscan.io/tx/",
    AAVE: "https://etherscan.io/tx/",
    GRT: "https://etherscan.io/tx/",
    MKR: "https://etherscan.io/tx/",
    SAND: "https://etherscan.io/tx/",
    COMP: "https://etherscan.io/tx/",
    BCH: "https://blockchair.com/bitcoin-cash/transaction/",
    XRP: "https://bithomp.com/explorer/",
    ALGO: "https://explorer.perawallet.app/tx/",
    SOL: "https://explorer.solana.com/tx/",
    AVAX: "https://subnets.avax.network/p-chain/tx/",
    DOGE: "https://blockchair.com/dogecoin/transaction/",
    LTC: "https://blockchair.com/litecoin/transaction/",
    WIF: "https://explorer.solana.com/tx/",
    JUP: "https://explorer.solana.com/tx/",
    SNX: "https://etherscan.io/tx/",
    WOO: "https://etherscan.io/tx/",
    FET: "https://etherscan.io/tx/",
    YFI: "https://etherscan.io/tx/",
  },
}
const OrderUtils = {
  /**
   * Returns the colorful icon of user's risk profile
   * @param riskProfile
   * @returns {string}
   */
  renderRiskProfile: function (riskProfile) {
    let color = "white"
    switch (riskProfile) {
      case 1:
        color = "rgb(70, 136, 71)"
        break
      case 2:
        color = "rgb(248, 148, 6)"
        break
      case 3:
        color = "rgb(255, 51, 0)"
        break
      default:
      // do nothing
    }
    return <i className={"fas fa-circle fa-xs"} style={{ color }}></i>
  },

  /**
   * Returns the current (latest) buy order status
   * @param order
   * @returns {string}
   */
  getCurrentBuyOrderStatus: function (order) {
    if (order.completed) {
      return "completed"
    } else if (order.sentToPaymentGateway) {
      return "sentToPaymentGateway"
    } else if (order.tradeCompleted) {
      return "tradeCompleted"
    } else if (order.sentToExchange) {
      return "sentToExchange"
    } else if (order.paid) {
      if (order.refunded) {
        return "refunded"
      } else if (order.frozen) {
        return "frozen"
      } else {
        return "paid"
      }
    } else if (order.issued) {
      if (order.deleted) {
        return "deleted"
      } else {
        return "issued"
      }
    }
  },

  /**
   * Returns the current (latest) sell order status
   * @param order
   * @returns {string}
   */
  getCurrentSellOrderStatus: function (order) {
    if (order.refunded) {
      return "refunded"
    }
    if (order.sentToBank) {
      return "sentToBank"
    } else if (order.sold) {
      if (order.refunded) {
        return "refunded"
      } else if (order.frozen) {
        return "frozen"
      }
      if (order.inPayoutBatch) {
        return "inPayoutBatch"
      } else if (order.inPayoutFile) {
        return "inPayoutFile"
      } else if (order.hasPendingPayout) {
        return "inPayout"
      }
      return "sold"
    } else if (order.received) {
      if (order.frozen) {
        return "frozen"
      }
      return "received"
    } else if (order.receivedMempool) {
      if (order.frozen) {
        return "frozen"
      }
      return "receivedMempool"
    } else if (order.issued) {
      if (order.deleted) {
        return "deleted"
      } else {
        return "issued"
      }
    }
  },

  /**
   * Returns an appropriate badge for current buy order status
   * @param order
   * @returns {*}
   */
  getCurrentBuyOrderStatusBadge: function (order, options) {
    let statusData = {}
    if (this.isCurrentBuyOrderStatusIssued(order)) {
      statusData = {
        title: "Issued",
        icon: Cart,
        background: "#f0f0f0",
        textColor: "#D16161",
      }
    } else if (this.isCurrentBuyOrderStatusDeleted(order)) {
      statusData = {
        title: "Deleted",
        icon: Trash,
        background: "#e05c57",
        textColor: "#FFFFFF",
      }
    } else if (this.isCurrentBuyOrderStatusPaid(order)) {
      statusData = {
        title: "Paid",
        icon: Cash,
        background: "#b2e7bf",
        textColor: "#24773B",
      }
    } else if (this.isCurrentBuyOrderStatusFrozen(order)) {
      statusData = {
        title: "Frozen",
        icon: Freeze,
        background: "#e78435",
        textColor: "#FFFFFF",
      }
    } else if (this.isCurrentBuyOrderStatusRefunded(order)) {
      statusData = {
        title: "Refunded",
        icon: ExchangeWhite,
        background: "#1285e5",
        textColor: "#FFFFFF",
      }
    } else if (this.isCurrentBuyOrderStatusExchanged(order)) {
      statusData = {
        title: "Exchanged",
        icon: Exchange,
        background: "#e3f2ff",
        textColor: "#1285E5",
      }
    } else if (this.isCurrentBuyOrderStatusTradeCompleted(order)) {
      statusData = {
        title: "Trade completed",
        icon: Exchange,
        background: "#e3f2ff",
        textColor: "#1285E5",
      }
    } else if (this.isCurrentBuyOrderStatusSentToPaymentGateway(order)) {
      statusData = {
        title: "Crypto delivered",
        icon: ArrowUp,
        background: "#f0f3e3",
        textColor: "#159542",
      }
    } else if (this.isCurrentBuyOrderStatusCompleted(order)) {
      statusData = {
        title: "Completed",
        icon: Transfer,
        background: "#fbc94c",
        textColor: "#BD7618",
      }
    }

    return this.getOrderStatusBadge(statusData, options)
  },

  /**
   * Returns an appropriate badge for current sell order status
   * @param order
   * @returns {*}
   */
  getCurrentSellOrderStatusBadge: function (order, options) {
    let statusData = {}
    if (this.isCurrentSellOrderStatusIssued(order)) {
      statusData = {
        title: "Issued",
        icon: Cart,
        background: "#f0f0f0",
        textColor: "#D16161",
      }
    } else if (this.isCurrentSellOrderStatusDeleted(order)) {
      statusData = {
        title: "Deleted",
        icon: Trash,
        background: "#e05c57",
        textColor: "#FFFFFF",
      }
    } else if (this.isCurrentSellOrderStatusReceivedMempool(order)) {
      statusData = {
        title: "Mempool",
        icon: Cash,
        background: "#d5d5d5",
        textColor: "#6d6d6d",
      }
    } else if (this.isCurrentSellOrderStatusReceived(order)) {
      statusData = {
        title: "Crypto received",
        icon: Cash,
        background: "#b2e7bf",
        textColor: "#24773B",
      }
    } else if (this.isCurrentSellOrderStatusSold(order)) {
      statusData = {
        title: "Sold",
        icon: Exchange,
        background: "#e3f2ff",
        textColor: "#1285E5",
      }
    } else if (this.isCurrentSellOrderStatusInPayoutBatch(order)) {
      statusData = {
        title: "In payout batch",
        icon: Exchange,
        background: "#e3f2ff",
        textColor: "#1285E5",
      }
    } else if (this.isCurrentSellOrderStatusInPayoutFile(order)) {
      statusData = {
        title: "In payout file",
        icon: Exchange,
        background: "#e3f2ff",
        textColor: "#1285E5",
      }
    } else if (this.isCurrentSellOrderStatusInPayout(order)) {
      statusData = {
        title: "In payout",
        icon: Exchange,
        background: "#e3f2ff",
        textColor: "#1285E5",
      }
    } else if (this.isCurrentSellOrderStatusFrozen(order)) {
      statusData = {
        title: "Frozen",
        icon: Freeze,
        background: "#e78435",
        textColor: "#FFFFFF",
      }
    } else if (this.isCurrentSellOrderStatusRefunded(order)) {
      statusData = {
        title: "Refunded",
        icon: ExchangeWhite,
        background: "#1285e5",
        textColor: "#FFFFFF",
      }
    } else if (this.isCurrentSellOrderStatusSentToBank(order)) {
      statusData = {
        title: "Fiat sent to customer",
        icon: Transfer,
        background: "#fbc94c",
        textColor: "#BD7618",
      }
    }

    return this.getOrderStatusBadge(statusData, options)
  },

  /**
   * Returns a list of tag badges for an order
   * @param order
   * @returns {Array}
   */
  getOrderTagBadges: function (order, options) {
    let tagsData = []
    if (order.commented) {
      tagsData.push({
        title: "Comment",
        icon: Comment,
        background: APP_COLORS.status_commented,
        textColor: "#FFFFFF",
      })
    }

    let tagBadges = []
    tagsData.forEach(item => {
      tagBadges.push(this.getOrderStatusBadge(item, options))
    })

    return tagBadges
  },

  /**
   * Returns badge layout
   * @param statusData
   * @returns {*}
   */
  getOrderStatusBadge: function (statusData, options) {
    const showTitle = options && options.iconOnly ? false : true
    const style = {
      backgroundColor: statusData.background,
      color: statusData.textColor,
    }

    if (!showTitle) {
      style.height = "25px"
      style.padding = "5px 5px 5px 26px"
    }

    return (
      <div
        key={statusData.title}
        className="status-badge"
        style={style}
        title={statusData.title}
      >
        <img src={statusData.icon} alt="" />
        {showTitle ? statusData.title : ""}
      </div>
    )
  },

  /**
   * Returns data grid columns for order bitstamp transactions
   * @returns {*[]}
   */
  getBitstampTransactionTableColumn: function () {
    return [
      {
        key: "id",
        name: "ID",
        width: 225,
      },
      {
        key: "inAmountData",
        name: "In amount",
        formatter: BitstampTransactionAmountFormatter,
      },
      {
        key: "outAmountData",
        name: "Out amount",
        formatter: BitstampTransactionAmountFormatter,
      },
      {
        key: "orderStatus",
        name: "Status",
      },
      {
        key: "timestamp",
        name: "Timestamp",
        formatter: DataGridLocalDateTimeFormatter,
      },
    ]
  },

  getEnigmaTransactionTableColumn: function () {
    return [
      {
        key: "id",
        name: "ID",
        width: 225,
      },
      {
        key: "inAmountData",
        name: "Traded amount",
        formatter: BitstampTransactionAmountFormatter,
      },
      {
        key: "outAmountData",
        name: "Out amount",
        formatter: BitstampTransactionAmountFormatter,
      },
      {
        key: "timestamp",
        name: "Created",
        formatter: DataGridLocalDateTimeFormatter,
      },
    ]
  },

  getBlockchainTransactionTableColumn: function () {
    return [
      {
        key: "id",
        name: "ID",
        width: 225,
      },
      {
        key: "inAmountData",
        name: "In amount",
        formatter: BitstampTransactionAmountFormatter,
      },
      {
        key: "outAmountData",
        name: "Out amount",
        formatter: BitstampTransactionAmountFormatter,
      },
      {
        key: "status",
        name: "Status",
      },
      {
        key: "timestamp",
        name: "Timestamp",
        formatter: DataGridLocalDateTimeFormatter,
      },
    ]
  },

  /**
   * Returns appropriate data row formatting for order bitstamp transactions
   * @param row
   * @param isSellOrder
   * @returns {*}
   */
  getBitstampTransactionDataRow: function (row, isSellOrder) {
    if (row) {
      if (!isSellOrder) {
        let inCurrency = row.currencyPair ? row.currencyPair.split("/")[1] : ""
        row.inAmountData = {
          amount: row.counterAmount,
          currency: inCurrency,
        }

        let outCurrency = row.currencyPair ? row.currencyPair.split("/")[0] : ""
        row.outAmountData = {
          amount: row.baseAmount,
          currency: outCurrency,
        }
      } else {
        let inCurrency = row.currencyPair ? row.currencyPair.split("/")[0] : ""
        row.inAmountData = {
          amount: row.baseAmount,
          currency: inCurrency,
        }

        let outCurrency = row.currencyPair ? row.currencyPair.split("/")[1] : ""
        row.outAmountData = {
          amount: row.counterAmount,
          currency: outCurrency,
        }
      }
    }
    return row
  },

  /**
   * Returns a block explorer url for a given currency and address, for example blockchain or etherscan.
   * @param cryptoCurrency
   * @param cryptoAddress
   * @returns {string}
   */
  getCryptoBlockExplorerUrl: function (
    cryptoCurrency,
    searchItem,
    type = "address",
  ) {
    if (type === "address" && cryptoCurrency === "XRP") {
      const split = searchItem.split(":")
      searchItem = split.length > 1 ? split[1] : searchItem
    }

    if (cryptoExplorerUrls[type] && cryptoExplorerUrls[type][cryptoCurrency]) {
      return cryptoExplorerUrls[type][cryptoCurrency] + searchItem
    }

    return ""
  },

  isCurrentBuyOrderStatusIssued: function (order) {
    return this.getCurrentBuyOrderStatus(order) === "issued"
  },

  isCurrentBuyOrderStatusDeleted: function (order) {
    return this.getCurrentBuyOrderStatus(order) === "deleted"
  },

  isCurrentBuyOrderStatusPaid: function (order) {
    return this.getCurrentBuyOrderStatus(order) === "paid"
  },

  isCurrentBuyOrderStatusFrozen: function (order) {
    return this.getCurrentBuyOrderStatus(order) === "frozen"
  },

  isCurrentBuyOrderStatusRefunded: function (order) {
    return this.getCurrentBuyOrderStatus(order) === "refunded"
  },

  isCurrentBuyOrderStatusExchanged: function (order) {
    return this.getCurrentBuyOrderStatus(order) === "sentToExchange"
  },

  isCurrentBuyOrderStatusCompleted: function (order) {
    return this.getCurrentBuyOrderStatus(order) === "completed"
  },

  isCurrentBuyOrderStatusSentToPaymentGateway: function (order) {
    return this.getCurrentBuyOrderStatus(order) === "sentToPaymentGateway"
  },

  isCurrentBuyOrderStatusTradeCompleted: function (order) {
    return this.getCurrentBuyOrderStatus(order) === "tradeCompleted"
  },

  isCurrentSellOrderStatusIssued: function (order) {
    return this.getCurrentSellOrderStatus(order) === "issued"
  },

  isCurrentSellOrderStatusDeleted: function (order) {
    return this.getCurrentSellOrderStatus(order) === "deleted"
  },

  isCurrentSellOrderStatusReceivedMempool: function (order) {
    return this.getCurrentSellOrderStatus(order) === "receivedMempool"
  },

  isCurrentSellOrderStatusReceived: function (order) {
    return this.getCurrentSellOrderStatus(order) === "received"
  },

  isCurrentSellOrderStatusSold: function (order) {
    return this.getCurrentSellOrderStatus(order) === "sold"
  },

  isCurrentSellOrderStatusInPayoutBatch: function (order) {
    return this.getCurrentSellOrderStatus(order) === "inPayoutBatch"
  },

  isCurrentSellOrderStatusInPayoutFile: function (order) {
    return this.getCurrentSellOrderStatus(order) === "inPayoutFile"
  },

  isCurrentSellOrderStatusInPayout: function (order) {
    return this.getCurrentSellOrderStatus(order) === "inPayout"
  },

  isCurrentSellOrderStatusSentToBank: function (order) {
    return this.getCurrentSellOrderStatus(order) === "sentToBank"
  },

  isCurrentSellOrderStatusFrozen: function (order) {
    return this.getCurrentSellOrderStatus(order) === "frozen"
  },

  isCurrentSellOrderStatusRefunded: function (order) {
    return this.getCurrentSellOrderStatus(order) === "refunded"
  },
}

export const BitstampTransactionAmountFormatter = props => {
  return <span>{props.value.amount + " " + props.value.currency}</span>
}

export default OrderUtils

import React, { useState, useEffect, useMemo } from "react"
import ContentWrapper from "../../Layout/ContentWrapper"
import { Row, Col } from "reactstrap"
import SellOrderInfoArea from "./SellOrderInfoArea"
import SellOrderActionsArea from "./SellOrderActionsArea"
import BackOfficeApi from "../../Api/BackOfficeApi"
import ToastUtils from "../../Common/ToastUtils"
import Utils from "../../Common/Utils"
import CommentArea from "../../Common/CommentArea"
import StorageUtils from "../../Common/StorageUtils"
import RefundModal from "./RefundModal"
import EditTags from "../../Common/EditTags"
import EditSellOrderModal from "./EditSellOrderModal"
import TradeModal from "../TradeModal"
import XmlReport from "../XmlReport"

const SellOrder = props => {
  const [order, setOrder] = useState(null)
  const [payoutMethods, setPayoutMethods] = useState([])
  const [customerBankAccounts, setCustomerBankAccounts] = useState([])
  const [commentReloadTrigger, setCommentReloadTrigger] = useState(0)

  const [isFetchingOrder, setIsFetchingOrder] = useState(false)
  const [isExecutingAction, setIsExecutingAction] = useState(false)

  const [refundModal, setRefundModal] = useState(false)
  const [editModal, setEditModal] = useState(false)
  const [tradeModal, setTradeModal] = useState(false)
  const [reportXmlModal, setReportXmlModal] = useState(false)
  const [editTagsModal, setEditTagsModal] = useState(false)
  const [fastXmlExport, setFastXmlExport] = useState(false)

  const orderId = useMemo(
    () => Utils.getLastUrlSegment(props.location),
    [props.location],
  )

  const getOrder = () => {
    setIsFetchingOrder(true)
    BackOfficeApi.endpoints.sellOrderDetails
      .getOne({ id: orderId })
      .then(response => {
        if (response.ok) {
          return response.json()
        }
        throw new Error()
      })
      .then(data => {
        setIsFetchingOrder(false)
        setOrder(data)
      })
      .catch(() => {
        ToastUtils.toastAPIError2()
        setIsFetchingOrder(false)
      })
  }

  const getPayoutMethods = async () => {
    const response = await BackOfficeApi.endpoints.getPayoutMethods.getAll()
    if (!response?.ok) {
      Utils.displayError(response)
      return
    }
    const data = await response.json()
    setPayoutMethods(data)
  }

  const getCustomerBankAccounts = async customerId => {
    const response = await BackOfficeApi.endpoints.customerBankAccounts.getOne({
      customerId,
    })
    if (!response?.ok) {
      Utils.displayError(response)
      return
    }
    const data = await response.json()
    setCustomerBankAccounts(data)
  }

  const onActionPerformed = action => {
    switch (action) {
      case "order_trade":
        toggleModal(setTradeModal)
        break
      case "order_mark_as_sent":
        markOrderAsSent()
        break
      case "order_freeze":
        freezeOrder()
        break
      case "order_unfreeze":
        unfreezeOrder()
        break
      case "order_refund":
        toggleModal(setRefundModal)
        break
      case "order_delete":
        deleteOrder()
        break
      case "order_edit":
        toggleModal(setEditModal)
        break
      case "report_xml":
        setFastXmlExport(false)
        toggleModal(setReportXmlModal)
        break
      case "fast_xml_export":
        toggleModal(setFastXmlExport)
        break
      case "queue_payout":
        queuePayout()
        break
      default:
        break
    }
  }

  const queuePayout = () => {
    setIsExecutingAction(true)
    BackOfficeApi.endpoints.queueSellOrderPayout
      .post({ orderId: order.orderMapId })
      .then(response => {
        if (response.ok) {
          getOrder()
        } else {
          ToastUtils.toastExecuteError2()
        }
        setIsExecutingAction(false)
      })
  }

  const onTrade = provider => {
    setIsExecutingAction(true)
    let formData = new FormData()
    formData.append("orderid", orderId)
    formData.append("provider", provider)
    BackOfficeApi.endpoints.tradeSellOrder
      .createLegacyRedirect(formData)
      .then(response => {
        if (response.ok) {
          getOrder()
        } else {
          ToastUtils.toastExecuteError2()
        }
        setIsExecutingAction(false)
      })
      .catch(() => {
        ToastUtils.toastSaveError2()
        setIsExecutingAction(false)
      })

    toggleModal(setTradeModal)
  }

  const markOrderAsSent = () => {
    let formData = new FormData()
    formData.append("orderId", orderId)
    createAction(BackOfficeApi.endpoints.sendSellOrder, formData)
  }

  const freezeOrder = () => {
    createAction(BackOfficeApi.endpoints.freezeSellOrder, {}, { id: orderId })
  }

  const unfreezeOrder = () => {
    createAction(BackOfficeApi.endpoints.unfreezeSellOrder, {}, { id: orderId })
  }

  const refundSellOrder = (
    refundReason,
    refundDate = "",
    cryptoAddress = "",
    cryptoAmount = "",
  ) => {
    let formData = new FormData()
    formData.append("reason", refundReason)
    let endpoint

    if (order.walletOrder) {
      endpoint = BackOfficeApi.endpoints.refundWalletSellOrder
    } else {
      formData.append("refundDate", refundDate)
      formData.append("refundAddress", cryptoAddress)
      formData.append("refundAmount", cryptoAmount)
      endpoint = BackOfficeApi.endpoints.refundSellOrder
    }

    createAction(endpoint, formData, { id: orderId })
  }

  const deleteOrder = () => {
    let formData = new FormData()
    formData.append("userId", StorageUtils.getUserId())
    formData.append("issellorder", "true")
    formData.append("orderid", orderId)
    formData.append("lazydelete", "true")
    createAction(BackOfficeApi.endpoints.deleteOrder, formData)
  }

  const toggleModal = setModalState => {
    setModalState(prevState => !prevState)
  }

  const editTagsOnChange = () => {
    setEditTagsModal(false)
    getOrder()
  }

  const onEditSave = () => {
    ToastUtils.toastSaveSuccess2()
    setEditModal(false)
    getOrder()
    setCommentReloadTrigger(commentReloadTrigger + 1)
  }

  const createAction = (endpoint, formData, ids) => {
    setIsExecutingAction(true)
    endpoint
      .create(formData, ids)
      .then(response => {
        if (!response.ok) {
          throw new Error()
        }
      })
      .then(() => {
        getOrder()
        setIsExecutingAction(false)
      })
      .catch(() => {
        ToastUtils.toastSaveError2()
        setIsExecutingAction(false)
      })
  }

  useEffect(() => {
    getOrder()
    getPayoutMethods()
  }, [orderId])

  useEffect(() => {
    if (order) {
      getCustomerBankAccounts(order.customerId)
    }
  }, [order])

  if (!order) {
    return null
  }

  const isLoading = isFetchingOrder || isExecutingAction
  return (
    <ContentWrapper>
      <Row>
        <Col lg={8}>
          <SellOrderInfoArea
            order={order}
            isLoading={isLoading}
            onEditTagClick={() => toggleModal(setEditTagsModal)}
          />
        </Col>
        <Col lg={4}>
          <Col className="pl-0 pr-0">
            <SellOrderActionsArea
              order={order}
              isLoading={isLoading}
              onActionPerformed={onActionPerformed}
            />
          </Col>
          <Col className="pl-0 pr-0">
            {order && (
              <CommentArea
                id={order.orderMapId}
                type="ORDER"
                reloadTrigger={commentReloadTrigger}
              />
            )}
          </Col>
        </Col>
      </Row>
      <RefundModal
        open={refundModal}
        toggleModal={() => toggleModal(setRefundModal)}
        onRefund={refundSellOrder}
        cryptoAmount={order ? order.receivedAmount : 0}
        walletOrder={order ? order.walletOrder : false}
      />
      <EditTags
        order={order}
        open={editTagsModal}
        toggle={() => toggleModal(setEditTagsModal)}
        onChange={editTagsOnChange}
        type="ORDER"
      />
      <TradeModal
        order={order}
        open={tradeModal}
        onToggle={() => toggleModal(setTradeModal)}
        onTrade={onTrade}
      />
      <EditSellOrderModal
        order={order}
        open={editModal}
        onToggle={() => toggleModal(setEditModal)}
        onSave={onEditSave}
        customerBankAccounts={customerBankAccounts}
        payoutMethods={payoutMethods}
      />
      {order && (
        <XmlReport
          orderOrTransactionIds={order.orderMapId}
          customerId={order.customerId}
          open={reportXmlModal}
          onToggle={() => toggleModal(setReportXmlModal)}
          fastXmlExport={fastXmlExport}
        />
      )}
    </ContentWrapper>
  )
}

export default SellOrder

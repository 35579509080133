// GLOBAL CONSTANTS
// -----------------------------------

export const APP_COLORS = {
  primary: "#5d9cec",
  primary_text_color: "#333333",
  inverted_text_color: "#FFFFFF",
  success: "#27c24c",
  info: "#23b7e5",
  warning: "#ff902b",
  danger: "#f05050",
  inverse: "#131e26",
  green: "#37bc9b",
  pink: "#f532e5",
  purple: "#7266ba",
  dark: "#3a3f51",
  yellow: "#fad732",
  gray_darker: "#232735",
  gray_dark: "#3a3f51",
  gray: "#dde6e9",
  gray_light: "#e4eaec",
  gray_lighter: "#edf1f2",
  green_light: "#E6F1F0",
  yellow_light: "#FFFFBA",
  red_light: "#FFCCCC",
  washed_green: "#56BAA5",
  light_emerald: "#C9DCDA",
  ethereal_green: "#E6F2F1",
  warm_red: "#F0603A",
  dirty_pink: "#DEB6C8",
  ghostly_pink: "#F0DFE8",
  deep_blue: "#29315E",
  bright_yellow: "#FFD754",
  sunbleached_yellow: "#FEF3CE",
  status_paid: "#f89406",
  status_sold: "#f89406",
  status_sent: "#3a87ad",
  status_traded: "#f89406",
  status_delivered: "#3a87ad",
  status_received: "#3a87ad",
  status_completed: "#468847",
  status_deleted: "#333333",
  status_frozen: "#0e90d2",
  status_refunded: "#FF3300",
  status_commented: "#7f4306",
  status_suspicious: "#232735",
  status_active: "#468847",
  status_inactive: "#FF3300",
  risk_low: "#468847",
  risk_medium: "#f89406",
  risk_high: "#FF3300",
  risk_extreme: "#000000",
}

export const APP_MEDIAQUERY = {
  desktopLG: 1200,
  desktop: 992,
  tablet: 768,
  mobile: 480,
}

// Coins that don't support balancer (buy/sell)
export const NOT_SUPPORTED_CRYPTO_BALANCER = ["XRP", "USDT", "SHIB", "LDO"]
// Coins that don't support hot wallets (withdrawal/deposit)
export const NOT_SUPPORTED_CRYPTO_HOT_WALLETS = [
  "ADA",
  "AVAX",
  "DOT",
  "FTM",
  "LDO",
  "NEAR",
  "SHIB",
  "USDT",
  "XRP",
  "JUP",
  "WIF",
]

export const API_ID_STAGING = "1234-5678-2"
export const API_ID_PROD = "57914701-800a-4aa9-89f8-7274299fb910"

// Wallets that you are able to do internal-transfer to
export const IMPORTANT_WALLETS = {
  staging: [
    {
      customerName: "Rich Pebo",
      customerId: 18444,
      walletId: 29,
    },
    {
      customerName: "Test company",
      customerId: 18660,
      walletId: 192,
    },
  ],
  production: [
    {
      customerName: "Police Seizure requests",
      customerId: 330361,
      walletId: 145207,
    },
    {
      customerName: "Bank Refund Request",
      customerId: 382286,
      walletId: 211681,
    },
  ],
}
